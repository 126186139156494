// src/components/QuestionContainer.js
import React, { useState } from 'react';

const QuestionContainer = ({ question, onAnswer, onContinue }) => {
    const [feedback, setFeedback] = useState('');
    const [isAnswered, setIsAnswered] = useState(false);
    const [correctness, setCorrectness] = useState(null);

    const handleAnswer = (selectedOption) => {
        if (isAnswered) return; // Prevent multiple answers

        const correct = selectedOption.toLowerCase() === question.answer.toLowerCase();
        setCorrectness(correct);
        setFeedback(correct
            ? 'Correct!'
            : `Incorrect. Correct answer: ${question.answer.toUpperCase()}. ${question[`option_${question.answer.toUpperCase()}`]}`
        );
        onAnswer(question.location, correct);
        setIsAnswered(true);
    };

    const handleContinue = () => {
        setFeedback('');
        setIsAnswered(false);
        setCorrectness(null);
        onContinue();
    };

    return (
        <div className="question-container">
            <div
                className="image-box"
                style={{ backgroundImage: `url(http://localhost:5000/images/${question.image || 'placeholder.png'})` }}
            ></div>
            <div className="question-text">
                {`Q${question.question_no} - ${question.question_text}`}
            </div>
            <ul className="options">
                {['A', 'B', 'C', 'D'].map(option => (
                    <li key={option}>
                        <button onClick={() => handleAnswer(option)}>
                            {`${option}. ${question[`option_${option}`]}`}
                        </button>
                    </li>
                ))}
            </ul>
            {feedback && (
                <>
                    <div className="feedback" style={{ color: correctness ? '#27ae60' : '#c0392b' }}>
                        {feedback}
                    </div>
                    <button className="continue-button" onClick={handleContinue}>
                        Continue
                    </button>
                </>
            )}
        </div>
    );
};

export default QuestionContainer;
