// src/App.js
import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import QuestionContainer from './components/QuestionContainer';
import ResultContainer from './components/ResultContainer';
import './App.css';

function App() {
    const [questions, setQuestions] = useState([]);
    const [sortedQuestions, setSortedQuestions] = useState([]);
    const [scores, setScores] = useState({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [quizCompleted, setQuizCompleted] = useState(false);

    useEffect(() => {
        fetch('http://localhost:5000/api/questions')
            .then(response => response.json())
            .then(data => {
                setQuestions(data);
                initializeScores(data);
                sortQuestions(data);
            })
            .catch(error => {
                console.error('Error fetching questions:', error);
            });
    }, []);

    const initializeScores = (questionsData) => {
        const initialScores = {};
        questionsData.forEach(q => {
            if (!initialScores[q.location]) {
                initialScores[q.location] = 0;
            }
        });
        setScores(initialScores);
    };

    const sortQuestions = (questionsData) => {
        const sorted = [...questionsData].sort((a, b) => {
            if (a.question_no !== b.question_no) {
                return a.question_no - b.question_no;
            }
            return a.location.localeCompare(b.location);
        });
        setSortedQuestions(sorted);
    };

    const handleAnswer = (location, isCorrect) => {
        setScores(prevScores => ({
            ...prevScores,
            [location]: prevScores[location] + (isCorrect ? 1 : 0)
        }));
    };

    const handleContinue = () => {
        if (currentQuestionIndex + 1 < sortedQuestions.length) {
            setCurrentQuestionIndex(prev => prev + 1);
        } else {
            setQuizCompleted(true);
        }
    };

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar scores={scores} />
            <div className="main">
                {!quizCompleted ? (
                    sortedQuestions.length > 0 ? (
                        <QuestionContainer
                            question={sortedQuestions[currentQuestionIndex]}
                            onAnswer={handleAnswer}
                            onContinue={handleContinue}
                        />
                    ) : (
                        <div className="question-container">
                            <p>Loading questions...</p>
                        </div>
                    )
                ) : (
                    <ResultContainer scores={scores} />
                )}
            </div>
        </div>
    );
}

export default App;
