// src/components/Sidebar.js
import React from 'react';

const Sidebar = ({ scores }) => {
    const locationList = Array.from({ length: 22 }, (_, i) => `c${i + 1}`);

    return (
        <div className="sidebar">
            <h2>Locations</h2>
            <ul className="locations">
                {locationList.map(loc => (
                    <li key={loc}>
                        {loc}: {scores[loc] || 0}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
